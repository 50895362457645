export const SET_ENABLED_REF_TYPES = 'SET_ENABLED_REF_TYPES';
export const SET_USE_SYMBOLIC_REF_NAMES = 'SET_USE_SYMBOLIC_REF_NAMES';
export const SET_PARAMS = 'SET_PARAMS';

export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const SET_SELECTED_REF = 'SET_SELECTED_REF';
export const SET_QUERY = 'SET_QUERY';

export const REQUEST_START = 'REQUEST_START';
export const REQUEST_FINISH = 'REQUEST_FINISH';

export const RECEIVE_BRANCHES_SUCCESS = 'RECEIVE_BRANCHES_SUCCESS';
export const RECEIVE_BRANCHES_ERROR = 'RECEIVE_BRANCHES_ERROR';

export const RECEIVE_TAGS_SUCCESS = 'RECEIVE_TAGS_SUCCESS';
export const RECEIVE_TAGS_ERROR = 'RECEIVE_TAGS_ERROR';

export const RECEIVE_COMMITS_SUCCESS = 'RECEIVE_COMMITS_SUCCESS';
export const RECEIVE_COMMITS_ERROR = 'RECEIVE_COMMITS_ERROR';
export const RESET_COMMIT_MATCHES = 'RESET_COMMIT_MATCHES';
